// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$hollywood-timeline-primary: mat-palette($mat-indigo);
$hollywood-timeline-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$hollywood-timeline-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$hollywood-timeline-theme: mat-light-theme((color: (primary: $hollywood-timeline-primary,
      accent: $hollywood-timeline-accent,
      warn: $hollywood-timeline-warn,
    )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($hollywood-timeline-theme);

$hollywood-timeline-typography: mat-typography-config($font-family: 'Josefin Sans');
$hollywood-timeline-header-typography: mat-typography-config($font-family: 'Limelight');
@include mat-core($hollywood-timeline-typography);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
  font-family: 'Limelight', cursive !important;
}
// #2D2A32 #DDD92A #EAE151 #EEEFA8 #FAFDF6